@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif;
  color: black;
}

body {
  font-size: 18px;
  font-family: "IBM Plex Sans" sans-serif;
}

section {
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  text-align: center;
}

/* ------------ Experience --------- */

.experience {
  background: radial-gradient(circle, #a7a5c6, #6d8a96, #8797b2);
}

.experience h1 {
  margin: 5% 0;
  color: black;
  text-align: center;
  margin-bottom: 3%;
  font-weight: bold;
}

.experience-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 5%;
}

.experience-card {
  width: 250px;
  background-color: white;
  cursor: pointer;
  border-radius: 10px;
  padding: 2% 2%;
  box-shadow: rgba(1, 0, 0.5, 0.5) 0 0 5px;
}

.experience-card:hover {
  box-shadow: rgba(1, 0, 0.5, 0.5) 0 0 15px;
}

.experience-content img {
  width: 80px;
  height: 80px;
  margin: 0px auto;
}

.experience-content h3 {
  font-weight: bold;
  margin-bottom: 2%;
}

.experience-content p {
  font-size: 18px;
  text-align: center;
}

.experience-tools p {
  font-weight: bold;
  font-style: italic;
  margin: 5% 0;
}

.experience-tools {
  list-style-type: none;
  padding: 10px;
}

.experience-tools li {
  display: block;
  font-size: 18px;
  margin-bottom: 2%;
}

/* ------------ Projects ------------- */

.project-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project-card {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 5px;
  transition: 0s ease;
}

.background-image {
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.3s ease;
}

.background-image.image1 {
  background-image: url("https://img.freepik.com/premium-vector/online-shopping-digital-technology-with-icon-blue-background-ecommerce-online-store-marketing_252172-219.jpg");
  background-size: cover;
  background-position: center;
}

.background-image.image2 {
  background-image: url("https://thumbs.dreamstime.com/b/medical-background-21578671.jpg?w=1400");
  background-size: cover;
  background-position: center;
}

.background-image.image3 {
  background-image: url("https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
}

.background-image.image4 {
  background-image: url("https://d3h2k7ug3o5pb3.cloudfront.net/image/2020-01-10/b7372130-3382-11ea-a700-a5080a3a032a.png");
  background-size: cover;
  background-position: center;
}

.background-image.image5 {
  background-image: url("https://img.freepik.com/premium-vector/creative-horizontal-website-screen-part-responsive-web-design-project-development-abstract-geometric-pattern-banner-layout-mock-up-corporate-landing-page-block-vector-illustration-template_181182-13902.jpg?w=2000");
  background-size: cover;
  background-position: center;
}

.background-image.image6 {
  background-image: url("https://img.freepik.com/premium-photo/responsive-design-e-magazine-website-devices-isolated-white-background-3d-rendering-mockup_72104-3724.jpg");
  background-size: cover;
  background-position: center;
}

.project-card:hover .background-image {
  opacity: 0.1;
}

.card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s ease;
  transform: translateY(-100%);
  background-size: cover;
}

.project-card:hover .card-content {
  transform: translateY(0);
}

.project-card:hover {
  transition: 0.3s ease;
}

.card-content h3,
.card-content p {
  /* margin: 0; */
  opacity: 1;
}

/* ------------ Contact -----------*/
label {
  font-size: 24px;
  margin-bottom: 2%;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #808080;
  border-radius: 5px;
  font-size: 18px;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #66ced6;
}